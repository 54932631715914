<template>
<!--  <router-view :key="$route.fullPath"></router-view>-->
  <router-view v-if="isRouterAlive"></router-view>
</template>

<script setup lang="ts">
import {nextTick, provide, ref} from "vue";

const isRouterAlive = ref(true)
const reload = ()=>{

  isRouterAlive.value = false
  nextTick(()=>{
    isRouterAlive.value = true
  })
}
provide('reload', reload)

</script>

<style lang="less" scoped>


</style>
