import { createRouter,RouteRecordRaw,createWebHashHistory } from 'vue-router';
import { App } from 'vue'
import Cookies from 'js-cookie'


const routes_children_list1 = [
	// 首页
	{
		path: '/home_index',
		name:'home_index',
		components: {
			home: () => import('../views/HomeIndex/home_index.vue'),
		}

	},
]
// 系统管理模块
const routes_children_list2 = [
	{
		path: '/user_management',
		name: 'user_management',
		components: {
			home:()=>import('../views/system_views/UserManagement/user_management.vue'),
		}
	},
	// 部门管理页面
	{
		path: '/divisional_management',
		name: 'divisional_management',
		components: {
			home:()=>import('../views/system_views/DivisionalManagement/divisional_management.vue'),
		},
	},
	// 页面管理
	{
		path: '/page_management',
		name: 'page_management',
		components: {
			home:()=>import('../views/system_views/PageManagement/page_management.vue'),
		},
	},
	// 角色管理
	{
		path: '/authority_management',
		name: 'authority_management',
		components: {
			home:()=>import('../views/system_views/AuthorityManagement/authority_management.vue'),
		},
	},
	// 数据维护
	{
		path: '/data_maintenance',
		name: 'data_maintenance',
		components: {
			home:()=>import('../views/system_views/DataMaintenance/data_maintenance.vue'),
		},
	},
	// 产品管理
	{
		path: '/product_management',
		name: 'product_management',
		components: {
			home:()=>import('../views/system_views/ProductManagement/product_management.vue'),
		},
	},
	// 样本库管理
	{
		path: '/storage_container',
		name: 'storage_container',
		components: {
			home:()=>import('../views/system_views/StorageContainer/storage_container.vue'),
		},
	},

	// 系统日志模块
	{
		path: '/request_log',
		name: 'request_log',
		components: {
			home:()=>import('../views/system_views/RequestLog/request_log.vue'),
		},
	},
]

// 客户管理模块
const routes_children_list3 = [
	// 客户管理
	{
		path: '/customer_management',
		name: 'customer_management',
		components: {
			home:()=>import('../views/customer_views/CustomerManagement/customer_management.vue'),
		},
	},
	// 意向管理
	{
		path: '/intention_management',
		name: 'intention_management',
		components: {
			home:()=>import('../views/customer_views/IntentionManagement/intention_management.vue'),
		},

	},
	// 合同管理
	{
		path: '/contract_management',
		name: 'contract_management',
		components: {
			home:()=>import('../views/customer_views/ContractManagement/contract_management.vue'),
		},

	},
	// 样本管理
	{
		path: '/sample_management',
		name: 'sample_management',
		components: {
			home:()=>import('../views/customer_views/SampleManagement/sample_management.vue'),
		},
	},
	// 样本数据
	{
		path: '/sample_data',
		name: 'sample_data',
		components: {
			home:()=>import('../views/customer_views/SampleData/sample_data.vue'),
		},
	},
	// 单细胞样本单管理
	{
		path: '/unicellular_sample',
		name: 'unicellular_sample',
		components: {
			home:()=>import('../views/customer_views/UnicellularSample/unicellular_sample.vue'),
		},
	},
]

// 财务管理模块
const routes_children_list4 = [
	// 预付款界面
	{
		path: '/advance_charge',
		name: 'advance_charge',
		components: {
			home:()=>import('../views/finance_views/AdvanceCharge/advance_charge.vue'),
		},
	},
	// 发票管理界面
	{
		path: '/invoice_management',
		name: 'invoice_management',
		components: {
			home:()=>import('../views/finance_views/InvoiceManagement/invoice_management.vue'),
		},
	},
	// 回款管理
	{
		path: '/collection_management',
		name: 'collection_management',
		components: {
			home:()=>import('../views/finance_views/CollectionManagement/collection_management.vue'),
		},
	},
	//转款管理
	{
		path: '/transfer_management',
		name: 'transfer_management',
		components: {
			home:()=>import('../views/finance_views/TransferManagement/transfer_management.vue'),
		},
	},
	// 外包账单核对
	{
		path: '/outsourcing_account',
		name: 'outsourcing_account',
		components: {
			home:()=>import('../views/finance_views/OutsourcingAccount/outsourcing_account.vue'),
		},
	},
]

// 工作台模块 report_management
const routes_children_list5 = [
	// 工作报告
	{
		path: '/report_management',
		name:'report_management',
		components: {
			home: () => import('../views/report_views/ReportManagement/report_management.vue'),
		}

	},
	// 实验工作日志
	{
		path: '/job_management',
		name:'job_management',
		components: {
			home: () => import('../views/report_views/JobManagement/job_management.vue'),
		}

	},

]

// 实验中心
const routes_children_list6 = [
	// 实验任务单
	{
		path: '/experiment_sheet',
		name:'experiment_sheet',
		components: {
			home: () => import('../views/experiment_views/ExperimentCenter/experiment_sheet.vue'),
		}

	},
	// 实验任务列表
	{
		path: '/experiment_list',
		name:'experiment_list',
		components: {
			home: () => import('../views/experiment_views/ExperimentList/experiment_list.vue'),
		}

	},
	// 分配列表
	{
		path: '/distribution_sheet',
		name:'distribution_sheet',
		components: {
			home: () => import('../views/experiment_views/ExperimentCenter/distribution_sheet.vue'),
		}

	},
	// 下机数据
	{
		path: '/off_board_data',
		name:'off_board_data',
		components: {
			home: () => import('../views/experiment_views/OffBoardData/off_board_data.vue'),
		}

	},
	// 下机报告
	{
		path: '/off_board_report',
		name:'off_board_report',
		components: {
			home: () => import('../views/experiment_views/OffBoardReport/off_board_report.vue'),
		}

	},
	// 下机数据备份
	{
		path: '/off_board_backup',
		name:'off_board_backup',
		components: {
			home: () => import('../views/experiment_views/OffBoardBackup/off_board_backup.vue'),
		}

	},

	// 分析任务单
	{
		path: '/analysis_task',
		name:'analysis_task',
		components: {
			home: () => import('../views/experiment_views/AnalysisTask/analysis_task.vue'),
		}
	},
	// 数据采集
	{
		path: '/analysis_collection',
		name:'analysis_collection',
		components: {
			home: () => import('../views/experiment_views/AnalysisCollection/analysis_collection.vue'),
		}
	},

	// 数据转移记录界面
	{
		path: '/data_transfer_log',
		name:'data_transfer_log',
		components: {
			home: () => import('../views/experiment_views/WBDataTransfer/wb_data_transfer_log.vue'),
		}
	},
	// 数据转移界面
	{
		path: '/data_transfer',
		name:'data_transfer',
		components: {
			home: () => import('../views/experiment_views/DataTransfer/data_transfer.vue'),
		}
	},
	// 数据释放
	{
		path: '/data_release',
		name:'data_release',
		components: {
			home: () => import('../views/experiment_views/DataRelease/data_release.vue'),
		}
	},
	// 三代数据统计
	{
		path: '/third_generation_data',
		name:'third_generation_data',
		components: {
			home: () => import('../views/experiment_views/ThirdGenerationData/third_generation_data.vue'),
		}
	},
	// 报告修改任务单
	{
		path: '/report_change_bill',
		name:'report_change_bill',
		components: {
			home: () => import('../views/experiment_views/ReportChangeBill/report_change_bill.vue'),
		}
	},
	// 三代数据统计 1
	{
		path: '/SequelII_data',
		name:'SequelII_data',
		components: {
			home: () => import('../views/experiment_views/SequelIIData/SequelII_data.vue'),
		}
	},
]

// 物料管理
const routes_children_list7 = [
	// 库存详情
	{
		path: '/material_stock',
		name:'material_stock',
		components: {
			home: () => import('../views/material_views/MaterialStock/material_stock.vue'),
		}
	},
	// 采购申请
	{
		path: '/purchasing_application',
		name:'purchasing_application',
		components: {
			home: () => import('../views/material_views/PurchasingApplication/purchasing_application.vue'),
		}
	},
	// 物料领用
	{
		path: '/material_drawing',
		name:'material_drawing',
		components: {
			home: () => import('../views/material_views/MaterialDrawing/material_drawing.vue'),
		}
	},
	// 采购详情
	{
		path: '/purchasing_details',
		name:'purchasing_details',
		components: {
			home: () => import('../views/material_views/PurchasingDetails/purchasing_details.vue'),
		}
	},
]


// 核酸检测
const routes_children_list8 = [
	// 核酸检测界面
	{
		path: '/nucleic_acid',
		name:'nucleic_acid',
		components: {
			home: () => import('../views/nucleic_acid_views/NucleicAcid/nucleic_acid.vue'),
		}
	},
	// 核酸检测数据
	{
		path: '/nucleic_data',
		name:'nucleic_data',
		components: {
			home: () => import('../views/nucleic_acid_views/NucleicData/nucleic_data.vue'),
		}
	},
	// 阳性数据
	{
		path: '/positive_data',
		name:'positive_data',
		components: {
			home: () => import('../views/nucleic_acid_views/PositiveData/positive_data.vue'),
		}
	},
]

// 项目管理
const routes_children_list9 = [
	// 项目管理
	{
		path: '/contract_progress',
		name:'contract_progress',
		components: {
			home: () => import('../views/report_views/ContractProgress/contract_progress.vue'),
		}

	},
]

// 外包管理
const routes_children_list10 = [
	// 外包价格
	{
		path: '/outsourcing_price',
		name:'outsourcing_price',
		components: {
			home: () => import('../views/outsourcing_views/OutsourcingPrice/outsourcing_price.vue'),
		}

	},
	// 外包数据
	{
		path: '/outsourcing_data',
		name:'outsourcing_data',
		components: {
			home: () => import('../views/outsourcing_views/OutsourcingData/outsourcing_data.vue'),
		}

	},
]

const children = routes_children_list1.concat(routes_children_list2,routes_children_list3,routes_children_list4,routes_children_list5,routes_children_list6,routes_children_list7,routes_children_list8,routes_children_list9,routes_children_list10)

const routes_parent = [
	{
		path: '/',
		name: 'index',
		redirect: '/home_index'
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/login.vue')
	},
	// 主界面
	{
		path: '/homepage',
		name: 'homepage',
		component: () => import('../views/homePage/homepage.vue'),
		children:children
	},
	// 测试界面
	{
		path: '/test',
		name: 'test',
		component: () => import('../views/test1/test4.vue')
	},

]
const routes_detail_list1 = [
	// 预付款管理详情页面
	{
		path: '/detail_advance_charge',
		name: 'detail_advance_charge',
		component: () => import('../views/finance_views/AdvanceCharge/detail_advance_charge.vue')
	},
	// 发票管理详情页面
	{
		path: '/detail_invoice_management',
		name: 'detail_invoice_management',
		component: () => import('../views/finance_views/InvoiceManagement/detail_invoice_management.vue')
	},
	// 回款详情界面
	{
		path: '/detail_collection_management',
		name: 'detail_collection_management',
		component: () => import('../views/finance_views/CollectionManagement/detail_collection_management.vue')
	},

]
const routes_detail_list2 = [
	// 实验中心详情界面
	{
		path: '/detail_experiment_sheet',
		name:'detail_experiment_sheet',
		component:()=> import('../views/experiment_views/ExperimentCenter/detail_experiment_sheet.vue')
	},
	// 实验中心预览检测报告
	{
		path: '/preview_examining_report',
		name:'preview_examining_report',
		component:()=> import('../views/experiment_views/ExperimentCenter/preview_examining_report.vue')
	},
	// 实验中心-单细胞预览 1 || 3
	{
		path: '/preview_monoplast_report_1',
		name:'preview_monoplast_report_1',
		component:()=> import('../views/experiment_views/ExperimentCenter/preview_monoplast_report_1.vue')
	},
	// 实验中心-单细胞预览 2
	{
		path: '/preview_monoplast_report_2',
		name:'preview_monoplast_report_2',
		component:()=> import('../views/experiment_views/ExperimentCenter/preview_monoplast_report_2.vue')
	},
	// 实验中心-单细胞预览 4
	{
		path: '/preview_monoplast_report_4',
		name:'preview_monoplast_report_4',
		component:()=> import('../views/experiment_views/ExperimentCenter/preview_monoplast_report_4.vue')
	},
	// 分析任务单详情界面
	{
		path: '/detail_analysis_task',
		name:'detail_analysis_task',
		component:()=> import('../views/experiment_views/AnalysisTask/detail_analysis_task.vue'),
		meta: {
			title: '任务分析单-详情'
		}
	},
	// 数据采集跳转
	{
		path: '/cj_skip',
		name:'cj_skip',
		component:()=> import('../views/experiment_views/AnalysisCollection/cj_skip.vue'),
		meta: {
			title: '数据采集-跳转'
		}
	},
	// 注释信息采集单界面
	{
		path: '/annotation_info',
		name:'annotation_info',
		component:()=> import('../views/experiment_views/AnalysisCollection/annotation_info.vue'),
		meta: {
			title: '数据采集-注释信息采集单'
		}
	},
	// 比较基因组分析采集单
	{
		path: '/genome_collection',
		name:'genome_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/genome_collection.vue'),
		meta: {
			title: '数据采集-比较基因组分析采集单'
		}
	},
	// WGD和共线性分析采集单
	{
		path: '/wgd_collection',
		name:'wgd_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/wgd_collection.vue'),
		meta: {
			title: '数据采集-WGD和共线性分析采集单'
		}
	},
	// NCBL数据上传
	{
		path: '/ncbl_collection',
		name:'ncbl_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/ncbl_collection.vue'),
		meta: {
			title: '数据采集-NCBL数据上传'
		}
	},
	// Survey分析信息采集单
	{
		path: '/survey_collection',
		name:'survey_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/survey_collection.vue'),
		meta: {
			title: '数据采集-Survey分析信息采集单'
		}
	},
	// 组装信息采集单
	{
		path: '/assemble_collection',
		name:'assemble_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/assemble_collection.vue'),
		meta: {
			title: '数据采集-组装信息采集单'
		}
	},
	// 泛基因组数据采集(三代)
	{
		path: '/pan_genome3',
		name:'pan_genome3',
		component:()=> import('../views/experiment_views/AnalysisCollection/pan_genome3.vue'),
		meta: {
			title: '数据采集-泛基因组(三代)'
		}
	},
	// 泛基因组数据采集(二代)
	{
		path: '/pan_genome2',
		name:'pan_genome2',
		component:()=> import('../views/experiment_views/AnalysisCollection/pan_genome2.vue'),
		meta: {
			title: '数据采集-泛基因组(二代)'
		}
	},
	// 转录组分析信息采集单
	{
		path: '/transcriptome_collection',
		name:'transcriptome_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/transcriptome_collection.vue'),
		meta: {
			title: '数据采集-转录组分析信息采集单'
		}
	},
	// 三维-Hi-C挂载/质控信息采集单 1
	{
		path: '/hi-c_collection1',
		name:'hi-c_collection1',
		component:()=> import('../views/experiment_views/AnalysisCollection/hi-c_collection1.vue'),
		meta: {
			title: '数据采集-Hi-C挂载/质控信息采集单'
		}
	},
	// 三维-ATAC-Seq/CUT&Tag/ChIP-Seq分析信息采集单 2
	{
		path: '/hi-c_collection2',
		name:'hi-c_collection2',
		component:()=> import('../views/experiment_views/AnalysisCollection/hi-c_collection2.vue'),
		meta: {
			title: '数据采集-ATAC-Seq/CUT&Tag/ChIP-Seq分析信息采集单'
		}
	},
	// 三维-Hi-C互作分析(含Capture HiC/4C)信息采集单 3
	{
		path: '/hi-c_collection3',
		name:'hi-c_collection3',
		component:()=> import('../views/experiment_views/AnalysisCollection/hi-c_collection3.vue'),
		meta: {
			title: '数据采集-Hi-C互作分析(含Capture HiC/4C)信息采集单'
		}
	},
	// 重测序-群体进化&GWAS&遗传图谱
	{
		path: '/gwas_collection',
		name:'gwas_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/gwas_collection.vue'),
		meta: {
			title: '数据采集-群体进化&GWAS&遗传图谱信息采集单'
		}
	},
	// 重测序-变异检测&外源插入片段检测
	{
		path: '/variation_detection_collection',
		name:'variation_detection_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/variation_detection_collection.vue'),
		meta: {
			title: '数据采集-变异检测&外源插入片段检测信息采集单'
		}
	},
	// 重测序-BSA
	{
		path: '/bsa_collection',
		name:'bsa_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/bsa_collection.vue'),
		meta: {
			title: '数据采集-BSA信息采集单'
		}
	},
	// 微生物-二代扩增子
	{
		path: '/amplicon2_collection',
		name:'amplicon2_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/amplicon2_collection.vue'),
		meta: {
			title: '数据采集-二代扩增子信息采集单'
		}
	},
	// 微生物-宏基因组项目标准信息分析收集表
	{
		path: '/hjyz_collection',
		name:'hjyz_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/hjyz_collection.vue'),
		meta: {
			title: '数据采集-宏基因组信息采集单'
		}
	},
	// 微生物-全长扩增子信息采集表
	{
		path: '/qckzz_collection1',
		name:'qckzz_collection1',
		component:()=> import('../views/experiment_views/AnalysisCollection/qckzz_collection1.vue'),
		meta: {
			title: '数据采集-全长扩增子信息采集表'
		}
	},
	// 微生物-细菌基因组信息采集表
	{
		path: '/germ_collection',
		name:'germ_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/germ_collection.vue'),
		meta: {
			title: '数据采集-细菌基因组信息采集表'
		}
	},
	// 单细胞-SmartSeq2-低通量信息采集表
	{
		path: '/SmartSeq2_collection1',
		name:'SmartSeq2_collection1',
		component:()=> import('../views/experiment_views/AnalysisCollection/SmartSeq2_collection1.vue'),
		meta: {
			title: '数据采集-SmartSeq2-低通量信息采集表'
		}
	},
	// 单细胞-SmartSeq2-高通量信息采集表
	{
		path: '/SmartSeq2_collection2',
		name:'SmartSeq2_collection2',
		component:()=> import('../views/experiment_views/AnalysisCollection/SmartSeq2_collection2.vue'),
		meta: {
			title: '数据采集-SmartSeq2-低通量信息采集表'
		}
	},

	// 单细胞-单细胞多组学&空间转录组信息采集表
	{
		path: '/spatial_transcriptome_collection',
		name:'spatial_transcriptome_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/spatial_transcriptome_collection.vue'),
		meta: {
			title: '数据采集-单细胞多组学&空间转录组信息采集表'
		}
	},
	// 单细胞-二代&三代免疫组库
	{
		path: '/immunity_collection',
		name:'immunity_collection',
		component:()=> import('../views/experiment_views/AnalysisCollection/immunity_collection.vue'),
		meta: {
			title: '数据采集-二代&三代免疫组库信息采集表'
		}
	},
	// 菲沙基因人类基因组重测序信息分析采集单 human genome
	{
		path: '/human_genome',
		name:'human_genome',
		component:()=> import('../views/experiment_views/AnalysisCollection/human_genome.vue'),
		meta: {
			title: '数据采集-人类基因组重测序信息分析采集单'
		}
	},


	// 分析任务单分组详情数据
	{
		path: '/detail_analysis_group',
		name:'detail_analysis_group',
		component:()=> import('../views/experiment_views/AnalysisTask/detail_analysis_group.vue'),
		meta: {
			title: '分析任务单分组详情数据'
		}
	},

	// 报告修改任务单详情页面
	{
		path: '/detail_report_change_bill',
		name:'detail_report_change_bill',
		component:()=> import('../views/experiment_views/ReportChangeBill/detail_report_change_bill.vue'),
		meta: {
			title: '报告修改任务单详情页面'
		}
	},
]
const routes_detail_list3 = [
	// 合同详情界面
	{
		path: '/detail_contract_management',
		name:'detail_contract_management',
		component:()=> import('../views/customer_views/ContractManagement/detail_contract_management.vue')
	},
	// 意向详情界面
	{
		path: '/detail_intention_management',
		name:'detail_intention_management',
		component:()=> import('../views/customer_views/IntentionManagement/detail_intention_management.vue')
	},
	// 客户详情界面
	{
		path: '/detail_customer_management',
		name:'detail_customer_management',
		component:()=> import('../views/customer_views/CustomerManagement/detail_customer_management.vue')
	},
	// 检测报告页面
	{
		path: '/examining_report',
		name:'examining_report',
		component:()=> import('../views/experiment_views/ExperimentCenter/examining_report.vue')
	},

	// 单细胞 Smart-seq2预扩增质检报告
	{
		path: '/monoplast_report_1',
		name:'monoplast_report_1',
		component:()=> import('../views/experiment_views/ExperimentCenter/monoplast_report_1.vue')
	},
	// 10X单细胞样本解离报告
	{
		path: '/monoplast_report_2',
		name:'monoplast_report_2',
		component:()=> import('../views/experiment_views/ExperimentCenter/monoplast_report_2.vue')
	},
	// 单细胞文库质检报告
	{
		path: '/monoplast_report_4',
		name:'monoplast_report_4',
		component:()=> import('../views/experiment_views/ExperimentCenter/monoplast_report_4.vue')
	},
]
const routes_detail_list4 = [
	// 系统通知界面
	{
		path: '/detail_message_center',
		name:'detail_message_center',
		component:()=> import('../components/details/detail_message_center.vue')
	},

	// 交接界面
	{
		path: '/handover',
		name:'handover',
		component:()=> import('../views/system_views/UserManagement/handover.vue'),
		meta: {
			title: '交接详情界面'
		}
	},
]
const routes_detail_list5 = [
	// 采购申请详情
	{
		path: '/detail_purchasing_application',
		name:'detail_purchasing_application',
		component: () => import('../views/material_views/PurchasingApplication/detail_purchasing_application.vue'),

	},
	// 物料申领详情
	{
		path: '/detail_material_drawing',
		name:'detail_material_drawing',
		component: () => import('../views/material_views/MaterialDrawing/detail_material_drawing.vue'),

	},
]
const parent = routes_parent.concat(routes_detail_list1,routes_detail_list2,routes_detail_list3,routes_detail_list4,routes_detail_list5)

const routes:RouteRecordRaw[] = parent
const router = createRouter({
	history: createWebHashHistory(),
    routes: routes,



})
const conllection_list = ['preview_examining_report','preview_monoplast_report_1','preview_monoplast_report_2','cj_skip','annotation_info','genome_collection','wgd_collection','ncbl_collection','survey_collection','assemble_collection','pan_genome3','pan_genome2','transcriptome_collection','hi-c_collection1','hi-c_collection2','hi-c_collection3','gwas_collection','variation_detection_collection','bsa_collection','amplicon2_collection','hjyz_collection','qckzz_collection','qckzz_collection1','germ_collection','SmartSeq2_collection1','SmartSeq2_collection2','spatial_transcriptome_collection','immunity_collection','human_genome']
 // 'preview_examining_report' || to.name == 'preview_monoplast_report_1' || to.name == 'preview_monoplast_report_2' || to.name == 'cj_skip' || to.name == 'annotation_info' || to.name == 'genome_collection' || to.name == 'wgd_collection' || to.name == 'ncbl_collection'
//全局守卫：登录拦截
router.beforeEach((to, from, next) => {
	const user_token = Cookies.get('user_token')
	if (to.meta.title) {
		(document as any).title = to.meta.title
	}
	if(conllection_list.indexOf(String(to.name)) > -1){

	}else{
		Cookies.set('HomePage',to.name)
	}

	if (to.name != 'login') {
	  if(conllection_list.indexOf(String(to.name)) > -1){
		  next()
	  }
	  else{
		  if (!user_token) {
			  next('login')
		  }else{
			  next()
		  }
	  }

	}
	else {
		next()
	}
})

export const initRouter = (app:App) => {
	app.use(router)
}
