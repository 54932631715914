import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus  from 'element-plus';


//引入element-plus样式文件 
import 'element-plus/theme-chalk/el-message.css';
import 'element-plus/theme-chalk/el-notification.css';
import 'font-awesome/css/font-awesome.min.css';
import 'element-plus/theme-chalk/el-loading.css'


// 导入全局样式表
import "./assets/css/global.css"

import {initRouter} from './router'
import {initVxeTable} from './vxetable'
const app = createApp(App);
app.use(ElementPlus);
initRouter(app)
initVxeTable(app)
app.mount('#app')
